import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

const LogoImage = () => {
  const { organizationId } = useSelector(
    (state) => state?.user?.userData?.user
  );

  let logoConfig = {
    uri: "/assets/svg/logo.gif",
    w: { xs: "120px", md: "150px" },
    h: 'fit-content',
    maxHeight: { xs: '40px', md: '60px' },
  };

  if (organizationId === 2 || organizationId === 3) {
    // Brookline Bank - Eng && Brookline Bank - Spa
    logoConfig = {
      uri: "/assets/svg/bb-logo.png",
      w: { xs: "120px", md: "240px" },
      h: 'fit-content',
      maxHeight: { xs: '60px', md: '90px' },
    };
  } else if (organizationId === 12) {
    //Tallmadge City School
    logoConfig = {
      uri: "/assets/svg/tallmadge-logo.png",
      w: { xs: "220px", md: "320px" },
      h: 'fit-content',
      maxHeight: { xs: '60px', md: '90px' },
    };
  } else if (organizationId === 11) {
    //  Essex Org
    logoConfig = {
      uri: "/assets/svg/essex-logo.png",
      w: { xs: "220px", md: "320px" },
      h: 'fit-content',
      maxHeight: { xs: '60px', md: '90px' },
    };
  } else if (organizationId === 10) {
    //Revere
    logoConfig = {
      uri: "/assets/svg/revere-logo.svg",
      w: { xs: "120px", md: "220px" },
      h: 'fit-content',
      maxHeight: { xs: '40px', md: '60px' },
    };
  } else if (organizationId === 9) {
    //  Everett High School
    logoConfig = {
      uri: "/assets/svg/everett-high-school.png",
      w: { xs: "100px", md: "120px" },
      h: 'fit-content',
      maxHeight: { xs: '40px', md: '50px' },
    };
  } else if (organizationId === 5 || organizationId === 4 || organizationId === 8 || organizationId === 13 || organizationId === 17) {
    // Healthy Relationship || Northwell
    logoConfig = {
      uri: "/assets/svg/northwell-logo.png",
      w: { xs: "120px", md: "240px" },
      h: 'fit-content',
      maxHeight: { xs: '60px', md: '90px' },
    };
  }
  else if (organizationId === 1) {
    // City of Everett
    logoConfig = {
      uri: "/assets/svg/everett-logo.png",
      w: { xs: "120px", md: "240px" },
      h: 'fit-content',
      maxHeight: { xs: '60px', md: '90px' },
    };
  }
  else if (organizationId === 14) {
    // BCH
    logoConfig = {
      uri: "/assets/svg/bch-logo.png",
      w: { xs: "180px", md: "250px" },
      h: 'fit-content',
      maxHeight: { xs: '60px', md: '90px' },
    };
  }
  else if (organizationId === 17) {
    // BCH
    logoConfig = {
      uri: "/assets/svg/newtown-logo.png",
      w: { xs: "180px", md: "250px" },
      h: 'fit-content',
      maxHeight: { xs: '60px', md: '90px' },
    };
  } else if (organizationId === 15) {
    // Stickball
    logoConfig = {
      uri: "/assets/svg/logo.gif",
      w: { xs: "120px", md: "150px" },
      h: 'fit-content',
      maxHeight: { xs: '40px', md: '60px' },
    };
  }

  return (
    <Box
      component="img"
      sx={{
        width: logoConfig.w,
        height: logoConfig.h,
        objectFit: 'contain',
        maxHeight: logoConfig.maxHeight,
        '@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)': {
          imageRendering: '-webkit-optimize-contrast',
          maxHeight: logoConfig.maxHeight,
        },
      }}
      src={logoConfig.uri}
      alt="Logo"
    />
  );
};

export default LogoImage;
