import React, { useState, useEffect, memo } from "react";
import { Box, IconButton } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Document, Page, pdfjs } from 'react-pdf';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
const PdfViewer = ({ pdfUrl, height }) => {

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }, []);


  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);


  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  





  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      mx: 'auto',
      alignItems: 'center', 
      position: 'relative',
      width: '95%',
      // overflow: 'hidden'
    }}>
      <p>
        Page {pageNumber} of {numPages}
      </p>
      <Document 
        file={pdfUrl} 
        onLoadSuccess={onDocumentLoadSuccess}
        loading="Loading PDF..."
        error="Failed to load PDF. Please try again."
      >
        <Page 
          pageNumber={pageNumber} 
          renderTextLayer={true}
          renderAnnotationLayer={true}
          width={undefined}
          scale={0.8}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </Document>

      <IconButton
        disabled={pageNumber === 1}
        onClick={() => setPageNumber(pageNumber - 1)}
        sx={{ ...btnStyle, left: '0px' }}>
        <ArrowBackIcon sx={{ ...iconStyle }} />
      </IconButton>
      <IconButton
        onClick={() => setPageNumber(pageNumber + 1)}
        disabled={pageNumber === numPages}
        sx={{ ...btnStyle, right: '0px' }}>
        <ArrowForwardIcon sx={{ ...iconStyle }} />
      </IconButton>
    </Box>
  );
};

export default memo(PdfViewer);
const iconStyle = {
  width: '40px',
  height: '40px',
  color: '#fff'
}

const btnStyle = {
  backgroundColor: 'primary.main',
  zIndex: 1000,
  height: '50px',
  width: '50px',
  boxShadow: (theme) => `0px 0px 2px 0px ${theme.palette.primary.main}`,
  position: 'absolute', 
  top: '50%',
  bottom: '50%',
  '&:hover': {
    boxShadow: (theme) => `0px 0px 5px 0px ${theme.palette.primary.main}`,
    backgroundColor: 'primary.main',
  },
  '&:disabled': {
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  }

}