import { Box, Button, Card, CardContent, useTheme } from "@mui/material";
import {
  ADMIN_LINK,
  checkQuilValidation,
  DATA_TYPE,
  getName,
  isAdminAdd,
  isValidUrl,
  LazzyLoading,
  renderPlaceholder,
} from "../../../common/contentType/contentTypeData";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Adminwrapper } from "../../../common";
import { useStyles } from "../../../../utils/styles";
import TypographyWithMore from "../../../common/contentType/TypographyWithMore";

const StoryBook = ({ content, user }) => {
  const { LINK } = DATA_TYPE;
  const { name, type, description } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: content?.allContent.content,
  });
  const { QUILL } = DATA_TYPE;
  const theme = useTheme();
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };

  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Link is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };

  const vURL = isValidUrl(data?.content) ? data?.content : "";

  return (
    <Box
      width="100%"
      height={{ xs: "80vh", md: "calc(100vh - 150px)" }}
      sx={{ overflowY: "auto", mt: "1rem" }}
    >
      <Adminwrapper
        errorText={contentFormik.errors.name}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="name"
      >
        {renderPlaceholder(data?.name, "Enter Name")}
        <TypographyWithMore
          variant="h2"
          color="primary"
          data={data?.name}
          textLength={18}
          lines={2}
          textAlign="center"
          width="100%"
        />
      </Adminwrapper>
      <Adminwrapper
        errorText={contentFormik.errors.description}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="description"
      >
        {renderPlaceholder(data?.description, "Enter Description")}
        <TypographyWithMore
          variant="body1"
          color="text"
          data={data?.description}
          textLength={40}
          lines={2}
          textAlign="center"
          width="100%"
        />
      </Adminwrapper>
      <Card sx={{ boxShadow: "none" }}>
        <CardContent
          sx={{
            p: "0px",
            borderRadius: "1.8rem",
            border: `1px solid ${theme.palette.common.disabled}`,
            mt: "1rem",
            height: { xs: "65vh", md: "calc(100vh - 240px)" },
            overflowY: "auto",
            "&:last-child": {

            },
          }}
        >
          <Adminwrapper
            errorText={contentFormik.errors.content}
            dataType={LINK}
            data={data}
            setData={setData}
            type="content"
            label={"i-frame"}
          >
            {!isIframeReady && <LazzyLoading />}
            <iframe
              onLoad={() => setIsIframeReady(true)

              }
              style={{
                opacity: isIframeReady ? 1 : 0,
                height: "98.5%",
                borderRadius: "1.875",
                width:"100%",
              }}
            //   className="i-frame"
              src={`${vURL}${vURL.includes("/?") ? "&" : "?"}userId=${getName(
                user
              )}&token=${localStorage.getItem("token")}&isIframe=true`}
              frameBorder="0"
            ></iframe>
          </Adminwrapper>
        </CardContent>
      </Card>
    </Box>
  );
};

export default StoryBook;
