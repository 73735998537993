import {
    Box, InputAdornment, Modal, TextField,
    MenuItem, Button, Menu, IconButton, Typography, Grid, useMediaQuery,
    DialogContentText,
    DialogActions,
    DialogContent,
    Dialog,
    DialogTitle,
    Link
} from "@mui/material"
import { useTheme } from "@mui/material/styles";
import SendIcon from '@mui/icons-material/Send';
import "./index.css"
import { sendMessageToGpt, sendMessageToModuleGpt } from "../_api/chat-bot";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useTranslation } from 'react-i18next'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import toast from "react-hot-toast";
import Tutorial from './page-tutorials'
import { useMutation } from "react-query";
import { userLog } from "../_api";
import { ChatIconCustom } from "./IconsSvg";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Markdown from 'react-markdown';
import { sendGenricEmail } from '../_api/chat-bot'
import moment from "moment/moment";
import { UAParser } from 'ua-parser-js'

const ChatBot = ({ data, pageName }) => {
    const [chatBotModal, setChatBotModal] = useState(false)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const unParsedData = localStorage.getItem("ModuleChatbot");
    const isModuleChatbot = unParsedData !== "false" ? JSON.parse(unParsedData) : "false";
    const user = useSelector((state) => state?.user?.userData?.user);
    const { mutate: log } = useMutation(userLog);
    return (
        <div style={{ position: "relative", }}>
            <ChatBotBody moduleId={isModuleChatbot?.id} user={user} isModuleChatbot={isModuleChatbot?.status} setChatBotModal={setChatBotModal} data={data} pageName={pageName} chatBotModal={chatBotModal} />
            {
                pageName == "Skill Section" ?
                    <IconButton
                        variant="rounded"
                        onClick={() => {
                            log({
                                pageName: 'Skills',
                                buttonName: 'Chat'
                            });
                            setChatBotModal(true)
                        }}
                    >
                        <ChatIconCustom width={isMobile ? "20px" : "30px"} height={isMobile ? "20px" : "30px"} color={theme.palette.common.white} />
                    </IconButton>
                    :
                    <div className={"chat-btn-2"}
                        style={{
                            boxShadow: "0px 0px 20px 10px rgba(255,255,255, 0.2)"
                        }}
                        onClick={() => {
                            log({
                                pageName: 'Skills',
                                buttonName: 'Chat'
                            });
                            setChatBotModal(true)
                        }}
                    >
                        <ChatIconCustom color={theme.palette.primary.main} />

                        {/* <img width={isMobile ? "30px" : "40px"} src={"../../assets/img/bot/bot-icon.svg"} alt="" /> */}
                    </div>
            }

        </div>
    )
}
export const ChatBotBody = ({ setChatBotModal, chatBotModal, data, pageName, user, isModuleChatbot, moduleId }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const { t } = useTranslation()
    const messagesEndRef = useRef()
    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [gradeNumber, setGradeNumber] = useState(12)
    const [anchorEl, setAnchorEl] = useState(null);
    const [isFullScreen, setIsFullScreen] = useState(true);
    const [openFlagged, setOpenFlagged] = useState(false)
    const [flaggedMessage, setFlaggedMessage] = useState("")
    const isContentViolated = sessionStorage.getItem("contentViolation") === "Yes"

    const open = Boolean(anchorEl);

    useEffect(() => {
        setGradeNumber(localStorage.getItem("gradeNumber") || 12)
    }, [])

    // Old Implementation 

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handlesendMessage = async () => {
        if (!inputText.trim()) return;

        const newMessages = [...messages, { sender: 'user', message: inputText }];
        let mesg = inputText
        setMessages(newMessages);
        setInputText('');

        try {
            setIsLoading(true)
            let botReply
            if (isModuleChatbot) {
                botReply = await sendMessageToModuleGpt({ prompt: inputText }, moduleId)
            } else {
                botReply = await sendMessageToGpt(newMessages, data, pageName, gradeNumber) // Pass newMessages as an argument
            }
            setIsLoading(false)
            if (botReply?.isFlagged) {
                setOpenFlagged(true)
                setFlaggedMessage(mesg)
                sessionStorage.setItem("contentViolation", "Yes")
                setMessages([...newMessages, { sender: 'assistant', message: botReply?.message }]);
                scrollToBottom()
                return null;
            }
            setMessages([...newMessages, { sender: 'assistant', message: botReply }]);
            scrollToBottom()

        } catch (error) {
            setIsLoading(false)
            console.error('Error sending message:', error);
            setChatBotModal(false)
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
            });
        }
    };



    useEffect(() => {
        scrollToBottom();
    }, [messages]);


    return (
        <>
            <FlaggedDialog open={openFlagged} setOpen={setOpenFlagged} inputMessage={flaggedMessage} />
            <Modal
                open={chatBotModal}
                onClose={() => setChatBotModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={isFullScreen ? 'chatbot-container-fullscreen' : 'chatbot-container'}>
                    {/* header */}
                    <Box sx={{ minWidth: { md: 800, sm: 500, xs: "100%" } }} className='chatbot-header-container'>
                        <div style={{ display: "flex", gap: 6, justifyContent: 'center', alignItems: "center" }}>
                            <div className="chat-bot-profile-pic">
                                <ChatIconCustom width={isMobile ? "40px" : "50px"} height="30px" color={theme.palette.primary.main} />
                                {/* <img width={isMobile ? "40px" : "50px"} src="../../assets/img/bot/bot-icon.svg" alt="" /> */}
                            </div>
                            <div style={{ fontSize: isMobile ? "10px" : "1rem", display: "flex", flexDirection: "column" }} >
                                <b>{t("askAnything")}</b>
                                <span style={{ margin: 0 }}>{t("online")}</span>
                            </div>
                            <Box sx={boxStyle}>
                                <Button
                                    endIcon={<KeyboardArrowDownIcon />}
                                    onClick={(event) => setAnchorEl(event.currentTarget)}
                                    variant="outlined"
                                    color="inherit"
                                    sx={readnessButtonStyle}
                                >
                                    {t("gradeLevel") + " " + gradeNumber}
                                </Button>
                                <IconButton
                                    onClick={() => {
                                        toast.success(`${t("gradeUpdatedMessage")} ${gradeNumber}`)
                                        localStorage.setItem("gradeNumber", gradeNumber)
                                    }}
                                    sx={{ borderRadius: '12px', px: { sm: 1.5, xs: .5 } }}
                                >
                                    <DoneRoundedIcon color="primary" />
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={() => setAnchorEl(null)}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    {
                                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => {
                                            return (
                                                <MenuItem key={item} sx={itemStyle}
                                                    onClick={() => {
                                                        setAnchorEl(null)
                                                        setGradeNumber(item)
                                                    }}>
                                                    {item == gradeNumber && <DoneRoundedIcon color="primary" sx={{ mr: 1 }} />} {t("grade")} {item}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Menu>
                            </Box>
                        </div>
                        <span
                            style={{ cursor: "pointer", marginRight: '5px', marginLeft: "5px" }}
                            onClick={() => {
                                setChatBotModal(false)
                            }}
                        >❌</span>

                    </Box>
                    {/* body */}
                    <Box sx={{
                        justifyContent: !messages.length && "center",
                        height: isFullScreen ? "100%" : "400px",
                        overflowY: "auto",
                        WebkitOverflowScrolling: "touch", // Enable momentum scrolling for iOS
                        "&::-webkit-scrollbar": {
                            width: "5px", // Adjust the width of the scrollbar
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "rgba(0, 0, 0, 0.3)", // Adjust the color of the scrollbar thumb
                            borderRadius: "5px", // Adjust the border radius of the scrollbar thumb
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the color of the scrollbar thumb on hover
                        },
                        "&::-webkit-scrollbar-track": {
                            backgroundColor: "transparent", // Set the background color of the scrollbar track to transparent
                        },
                    }} className='chatbot-body-container'>
                        {
                            !messages.length && <div className="col-12 d-flex justify-content-center align-items-center">
                                <Grid container spacing={3}
                                    sx={{ width: "70%", mt: 1 }}
                                >
                                    <Grid sm={6} xs={12}
                                        sx={{
                                            borderRadius: "10px",
                                            p: { sm: '10px', xs: "5px" },
                                            gap: { sm: '20px', xs: "10px" },
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: 'center',
                                            backgroundColor: (theme) => theme.palette.primary.main,
                                            overflow: "hidden"
                                        }}
                                    >
                                        <img width="35%" src="https://stickball.s3.amazonaws.com/bot-icon-white.svg" alt="" />
                                        <Typography sx={{ fontWeight: 700, color: 'white', fontSize: { xs: "18px", md: "35px" }, gap: "1rem", maxWidth: { sm: "170px", xs: '100px' } }} vriant="h4">
                                            {t("askAnything")}
                                        </Typography>
                                    </Grid>
                                    <Grid sm={6} xs={12}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Typography variant="body1" color="initial"
                                            sx={{
                                                ml: { sm: 3, xs: 0 },
                                                mt: { sm: 0, xs: 2 },
                                                fontWeight: { sm: 600, xs: 400 },
                                                fontSize: { sm: '1.5rem', xs: '0.9rem' }
                                            }}

                                        >
                                            {t("emptyChatText1")}
                                            <br />
                                            <br />
                                            {/* {t("emptyChatText2")} */}
                                            {/* <br />
                                        <br />
                                        {t("emptyChatText3")} */}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {/* <img width="41%" src="../assets/svg/no-data-2.png" alt="" /> */}
                            </div>
                        }
                        {messages?.map((val) => (
                            <>
                                <EachMessage isLoading={isLoading} val={val} />
                            </>
                        ))}
                        {isLoading && <EachMessage val={{ sender: 'assistant', message: t("typing") + "...", }} />}
                        <div ref={messagesEndRef} />
                    </Box>
                    {
                        isContentViolated &&
                        <Typography textAlign={"center"} variant="body1" color="error">
                            Your Ask Anything facility has been disabled. Please contact at <Link href="mailto:developers@stickball.biz">developers@stickball.biz</Link>
                        </Typography>
                    }
                    <div className='chatbot-header-container' style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <Box sx={{ m: 1 }}>
                            <Tutorial page="askAnything" />
                        </Box>
                        <TextField
                            disabled={isContentViolated}
                            size="small"
                            placeholder={t("enterQuestion")}
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handlesendMessage();
                                }
                            }}

                            id="outlined-basic"
                            sx={{
                                width: "90%",
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                            }}
                            variant="outlined"
                            InputProps={{
                                sx: { borderRadius: 50 },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SendIcon color={isContentViolated ? "disabled" : "primary"} sx={{ cursor: isContentViolated ? "not-allowed" : "pointer", }} onClick={handlesendMessage} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {
                            !isMobile &&
                            <IconButton
                                onClick={() => setIsFullScreen(!isFullScreen)}
                            >
                                {
                                    isFullScreen ?
                                        <FullscreenExitIcon sx={{ width: 35, height: 35 }} />
                                        :
                                        <FullscreenIcon sx={{ width: 35, height: 35 }} />
                                }
                            </IconButton>
                        }
                    </div>
                </div >
            </Modal >
        </>
    )
}
const EachMessage = ({ val }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const botBorderRadius = "0px 14px 14px 14px"
    const userBorderRadius = "14px 0px 14px 14px"
    const isBot = val.sender == "assistant";
    return (
        <>
            <div className={isBot ? "each-message" : "each-message-user"}>
                <div className="chat-bot-profile-pic" style={{ padding: 0, background: !isBot && "white" }}>
                    {isBot && <ChatIconCustom width={isMobile ? "30px" : "50px"} height={isMobile ? "30px" : "50px"} color={theme.palette.primary.main} />}
                    {!isBot && <AccountCircleIcon sx={{ width: isMobile ? "30px" : "50px", height: isMobile ? "30px" : "50px" }} color="primary" />}

                </div>
                <Box
                    sx={{
                        display: "flex",
                        padding: { sm: "10px 25px", xs: "5px 10px" },
                        borderRadius: isBot ? botBorderRadius : userBorderRadius,
                        backgroundColor: isBot ? theme.palette.primary.main : theme.palette.primary.light,
                        maxWidth: { sm: '50%', xs: "70%" },
                        width: "fit-content",
                        position: "relative",
                        top: { sm: "28px", xs: "16px" },
                        color: isBot ? "white" : "black",
                        alignItems: 'center'
                    }}>
                    <p className="chat-bot-message-text">
                        <Markdown
                            components={{
                                h1: (props) => <h1 style={{ color: 'inherit' }} {...props} />,
                                h2: (props) => <h2 style={{ color: 'inherit' }} {...props} />,
                                h3: (props) => <h3 style={{ color: 'inherit' }} {...props} />,
                                h4: (props) => <h4 style={{ color: 'inherit' }} {...props} />,
                                h5: (props) => <h5 style={{ color: 'inherit' }} {...props} />,
                                h6: (props) => <h6 style={{ color: 'inherit' }} {...props} />,

                            }}
                        >
                            {val?.message}
                        </Markdown>
                    </p>
                </Box>
            </div>
        </>
    )
}

export default ChatBot;


function getSystemAndBrowserInfo() {
    const parser = new UAParser();
    const result = parser.getResult();

    const deviceType = `${result.device.vendor} ${result.device.model || 'Desktop'}`;
    const os = `${result.os.name} ${result.os.version || ''}`;
    const browser = `${result.browser.name} ${result.browser.version || ''}`;

    return {
        deviceType,
        os,
        browser
    };
}

const FlaggedDialog = ({ open, setOpen, inputMessage }) => {
    const user = useSelector((state) => state?.user?.userData?.user);
    const systemInfo = getSystemAndBrowserInfo()

    useEffect(() => {
        const fetchUserInfo = async () => {
            let email = user?.email
            // const systemInfo = await navigator.userAgent;
            // const browserInfo = await navigator.appVersion;
            const userLocation = await new Promise((resolve) => {
                navigator.geolocation.getCurrentPosition((position) => {
                    resolve(position.coords);
                });
            });

            let tempPayload = {
                name: user?.firstName,
                email: email,
                system_info: systemInfo,
                latitude: userLocation.latitude,
                longitude: userLocation.longitude,
                current_time: new Date().toISOString(),
                organizationId: user?.organizationId,
                organizationName: "Production"
            }
            const message = `
Hello Stickball,

We have detected a content moderation violation while processing a request through OpenAI’s API. The details of this incident are as follows:


Input Content Triggering Violation

    The following input text was flagged by OpenAI's content moderation for a potential policy violation:

        Text: ${inputMessage}

        Date and Time of Violation: ${moment(tempPayload.current_time).format("MMM Do hh:mm:A")}

User Details

    • Full Name: ${tempPayload.name || "N/A"}
    • Email Address: ${tempPayload.email || "N/A"}

Organization Details

    • Organization ID: ${tempPayload.organizationId || "N/A"}
    • Organization Name: ${tempPayload.organizationName}

Application Details

    • Application Name: Client App
    • URL: ${window.location.href}
    • Environment: Production
    • Feature: Ask Anything Bot
    • Static Site: No


System and Browser Information

    • Device: ${tempPayload.system_info.deviceType || ""}
    • Operating System: ${tempPayload.system_info.os || ""}
    • Browser: ${tempPayload.system_info.browser || ""}
    • Timestamp: ${moment(tempPayload.current_time).format("hh:mm:A")}

Location Information

    • Latitude: ${tempPayload.latitude || "N/A"}
    • Longitude: ${tempPayload.longitude || "N/A"}

Please review this incident promptly to ensure appropriate action is taken.

Thank you.

Regards,
Development Team
`
            let mailInfoToSend = {
                to: ["developers@stickball.biz"],
                cc: [],
                subject: `Urgent OpenAI Violation Alert - ${tempPayload.organizationName} - Production Environment, Dynamic App`,
                message: message
            }
            if (open && inputMessage) {
                sendGenricEmail(mailInfoToSend)
            }

        };

        fetchUserInfo();
    }, [open, inputMessage]);
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="xs"
            PaperProps={{
                sx: {
                    borderRadius: "32px",
                    pt: "30px",
                    minHeight: "200px"
                }
            }}
        >
            <DialogTitle textAlign="center" color="error">
                Content Violation Alert
            </DialogTitle>
            <DialogContent>
                <DialogContentText textAlign="center" id="alert-dialog-description">
                    The message has content violations.
                    <br />
                    Your Ask Anything facility has been disabled. Please contact at <Link href="mailto:developers@stickball.biz">developers@stickball.biz</Link>
                </DialogContentText>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>

                </Box>
            </DialogContent>
            <DialogActions>
                <Button sx={{ mr: 2, mb: 1 }} variant="contained" onClick={() => setOpen(false)} >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const readnessButtonStyle = {
    fontSize: 'inherit',
    color: 'black',
    textTransform: 'none',
    borderRadius: "12px",
    padding: { sm: '10px 20px', xs: '5px' },
    background: 'white',
    border: '1px solid #D8D7D7',
    fontSize: { sm: "12px", xs: "10px" }
}

const boxStyle = {
    fontSize: { sm: '1rem', xs: "10px" },
    display: 'flex',
    width: 'fit-content',
    backgroundColor: '#616DED1A',
    borderRadius: "12px",
    gap: { sm: '5px', xs: "2px" },
    ml: { sm: 5, xs: 0 }
}
const itemStyle = {
    width: '150px',
    mx: '5px',
    borderRadius: '12px',
    '&:hover': {
        backgroundColor: '#106EAC',
        color: 'white'
    }
}