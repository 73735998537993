import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { userLog } from "../../_api/index";
import { setSession, userEnroll } from "../../../redux-features/user/userSlice";
import SkillSection from "../details/SkillSection";
import Box from "@mui/material/Box";
import SkillSectionLayout from "../../../layouts/SkillSectionLayout";
import { useStyles } from "../../../utils/styles";



export default function SkillAssess() {
    const dispatch = useDispatch();
    const skillSections = useSelector((state) => state.user.skillSections);
    const waitTime = useSelector(state => state.user.waitTime) || 30
    const { mutate: log } = useMutation(userLog);
    const navigate = useNavigate();
    const classes = useStyles()
    const [assessContent, setAssessContent] = useState([]);
    const [nextContent, setNextContent] = useState();
    const [ind, setInd] = useState(0);
    const [nextTimer, setNextTimer] = useState(waitTime || 30);
    const [introContent, setIntroContent] = useState([]);

    useEffect(() => {
        const timer = setInterval(() => {
            setNextTimer((prevProgress) => (prevProgress > 0 ? prevProgress - 1 : 0));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        let temp = skillSections?.filter((ss) => {
            return ss?.name === "Assess";
        });
        let temp1 = skillSections?.filter((ss) => {
            return ss?.name === "SIMS";
        });
        let temp2 = skillSections?.filter((ss) => {
            return ss?.name === "Intro";
        });

        setNextContent(temp1);
        setAssessContent(temp);
        setIntroContent(temp2);
        if (temp.length === 0) {
            navigate("/skill-detail-sims");
        }
    }, [skillSections, ind]);

    const handleEnrollment = (data, index) => {
        if (nextContent?.length === 0) {
            let fc = assessContent.filter((cont) => {
                return !cont.isCompleted;
            });
            if (fc.length === 0) {
                navigate("/skill-detail-quiz");
            } else {
                if (assessContent.length - 1 === index) {
                    dispatch(setSession(data));
                    navigate("/skill-detail-quiz");
                } else {
                    dispatch(userEnroll(data));
                    setInd(ind + 1)
                    setNextTimer(waitTime);
                }
            }
        } else {

            let fc = assessContent.filter((cont) => {
                return !cont.isCompleted;
            });
            if (fc.length === 0) {
                navigate("/skill-detail-sims");
            } else {
                try {
                    dispatch(userEnroll(data));
                    setNextTimer(waitTime);
                    setInd(ind + 1)
                    if (assessContent.length - 1 === index) {
                        navigate("/skill-detail-sims");
                    }
                } catch (err) {
                    console.log("Error: ", err)
                 }
            }
        }
    };

    const allEnrolled = assessContent.filter((content) => { return content.isCompleted }).length == assessContent.length

    const handleNextClick = () => {
        log({
            pageName: "Skill Details Assess",
            buttonName: "Next",
        });
        if (allEnrolled) {
            assessContent[ind + 1]
                ? setInd(ind + 1)
                : navigate("/skill-detail-sims");
        } else {
            handleEnrollment(assessContent[ind]?.id, ind);
        }
    }
    const handlePrevClick = () => {
        log({
            pageName: 'Skill Details Assess',
            buttonName: 'Prev'
        })
        if (ind <= 0) {
            if (!introContent.length) {
                window.history.go(-2)
            } else {
                window.history.go(-1)
            }
        } else {
            setInd(ind - 1)
        }

    }

    return (
        <SkillSectionLayout
            nextTimer={nextTimer}
            page="Skill Details Assess"
            handleNextClick={handleNextClick}
            handlePrevClick={handlePrevClick}
            isCompleted={allEnrolled}
        >
            <Box className={classes.skillSectionContainer}>
                <SkillSection content={assessContent[ind]} />
            </Box>
        </SkillSectionLayout>
    );
}
